import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { Card, Button, AppBar, Paper } from '@mui/material';

export const Footer = (props : { showTablesLogo : boolean }) => 
{
    let isMobile = Util.isMobileDevice();

    let outerContainerStyle : CSSProperties = 
    {
        background: Colors.veryDarkGrey,
        paddingLeft: 32,
        paddingRight: 32,
        borderTop: Styles.border,
        display: "grid"
    }

    let containerStyle : CSSProperties =
    {
        gridRow: 1,
        gridColumn: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "stretch",
        width: "100%",
        fontSize: 0,
        height: Util.getFooterHeight(),
        background: Colors.veryDarkGrey,
        zIndex: 10,
        maxWidth: 1000,
        marginLeft: "auto",
        marginRight: "auto"
    }

    let flexBoxContainerStyle = Styles.flexBoxContainer;
    flexBoxContainerStyle.justifyContent = "end";
    flexBoxContainerStyle.flexDirection = "row";
    flexBoxContainerStyle.width = "100%";

    let buttonStyle : CSSProperties =
    {
        color: Colors.midGrey,
        borderBottom: "4px solid " + Colors.midGrey,
        borderRadius: 0,
        margin: 0,
        fontFamily: Styles.defaultFont,
        fontWeight: 700
    }

    let activeButtonStyle = Util.copy(buttonStyle);
    activeButtonStyle.borderBottom = "4px solid " + Colors.tablesBlue;
    activeButtonStyle.color = Colors.white;

    let sectionStyle : CSSProperties =
    {
        flex: 1,
        boxSizing: "border-box",
        fontSize: "16px",
    }

    let rightSectionStyle = Util.copy(sectionStyle);
    rightSectionStyle.textAlign = "right";
    rightSectionStyle.flex = 2;

    let pStyle : CSSProperties = 
    {
        padding: 0, 
        margin: 0
    }

    let marginTopPStyle = Util.copy(pStyle);
    marginTopPStyle.marginTop = 16;

    let smallMarginTopPStyle = Util.copy(pStyle);
    smallMarginTopPStyle.marginTop = 8;

    let iconStyle = { width: 20, height: 20, marginRight: 8, verticalAlign: "bottom" };

    let rightIconStyle = { width: 20, height: 20, marginLeft: 8, verticalAlign: "bottom" };

    let tablesLogoContainer : CSSProperties = { gridRow: 1, gridColumn: 1 };

    if (!props.showTablesLogo)
    {
        tablesLogoContainer.display = "none";
    }

    let logoYOffset = isMobile ? 160 : 182;
    let tablesLogoStyle : CSSProperties = { width: 80, height: 80, margin: "auto", display: "block", transform: `translateY(-${logoYOffset}px)` };

    return (
        <div style={outerContainerStyle}>
            <div style={containerStyle}>
                <div style={flexBoxContainerStyle}>
                    <div style={sectionStyle}>
                        <p style={pStyle}><a href={"https://instagram.com/tablesapp"}><img style={iconStyle} src={Util.getImageUrl("icon-instagram.svg")}></img>Instagram</a></p>
                        <p style={marginTopPStyle}><a href={"https://facebook.com/tablesapp"}><img style={iconStyle} src={Util.getImageUrl("icon-facebook.svg")}></img>Facebook</a></p>
                    </div>
                    <div style={rightSectionStyle}>
                        <p style={pStyle}>Tables Solutions Ltd {new Date().getFullYear()}<img style={rightIconStyle} src={Util.getImageUrl("icon-copyright.svg")}></img></p>
                        <p style={marginTopPStyle}><a href={Util.getAbsoluteUrl("privacypolicy.html")}>Privacy Policy<img style={rightIconStyle} src={Util.getImageUrl("icon-info.svg")}></img></a></p>
                        <p style={marginTopPStyle}><a href={Util.getAbsoluteUrl("EULA.html")}>EULA<img style={rightIconStyle} src={Util.getImageUrl("icon-info.svg")}></img></a></p>
                    </div>
                </div>
            </div>

            <div style={tablesLogoContainer}>
                <img style={tablesLogoStyle} src={Util.getImageUrl("icon-tables-logo.svg")}></img>
            </div>
        </div>
    )
}